.searchPanelResultsGrow {
  max-height: 50vh;
  overflow: auto;
  animation-name: growHeight;
  animation-duration: 300ms;
}

@keyframes growHeight {
  from {
    height: 0vh;
    overflow: hidden;
  }
  to {
    height: 50vh;
    overflow: scroll;
  }
}